import React from 'react';

import config from '../../config';
import Layout from '../shared/Layout';

export default function Settings() {
  return (
    <Layout navigation={[{ current: true, name: 'Settings' }]}>
      <div
        style={{ height: '80vh' }}
        className="flex flex-col items-center justify-center text-center"
      >
        <div className="text-gray-500 max-w-sm">
          You do not have access to manage organization settings. Please contact
          your administrator.
        </div>
      </div>
    </Layout>
  );
}
