import React from 'react';
import { Link } from 'react-router-dom';

import { ArrowRightIcon } from '@heroicons/react/outline';

import config from '../../config';
import Header from '../shared/Header';
import Layout from '../shared/Layout';

const EMOJIS = ['👍', '👌', '✅', '😃', '🚀', '🎆'];

export default function Welcome() {
  if (config.useInvestigationMode || config.usePlatformMode) {
    return (
      <Layout navigation={[]}>
        <div className="p-8 mt-6 sm:mt-24 flex flex-col items-center">
          <div className="text-4xl">🚀 </div>
          <div className="mt-4 font-medium text-gray-600 max-w-sm">
            Welcome to Assured ClaimView! Please select one of your available
            products to continue:
          </div>
          {(config.useInvestigationMode
            ? [{ label: 'Intelligent Investigation', to: '/cases' }]
            : [{ label: 'Intake Dashboard', to: '/claims' }]
          ).map(({ label, to }) => (
            <div className="mt-4">
              <Link
                to={to}
                className="text-blue-500 hover:text-blue-600 font-medium flex items-center"
              >
                <span className="text-lg">{label}</span>
                <ArrowRightIcon className="w-5 h-5 ml-2" />
              </Link>
            </div>
          ))}
        </div>
      </Layout>
    );
  }

  return (
    <>
      <Header />
      <div
        style={{ height: '80vh' }}
        className="flex flex-col items-center justify-center text-center"
      >
        <div className="text-4xl">
          {EMOJIS[Math.round((EMOJIS.length - 1) * Math.random())]}
        </div>
        <div className="text-xl my-1 font-bold text-gray-600">
          {config.authentication
            ? "You're logged in."
            : 'Welcome to ClaimView.'}
        </div>
        <div className="text-gray-500 max-w-sm">
          Use a direct link from your email inbox to access a particular
          ClaimView.
        </div>
      </div>
    </>
  );
}
